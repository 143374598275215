import React from "react"
import { Box, Image, Badge, Grid } from "@chakra-ui/core"
import { Link } from "gatsby"
import Layout from "../../../components/layoutPhoenixCustom"
import BgImage from "../../../images/radicalLectures_big.jpg"
import LoveSong from "../../../images/Love_Song.jpg"
import Radical from "../../../images/radicalLectures.jpg"
import Growth from "../../../images/growthSeries.jpg"
import Christmas from "../../../images/christmasSeries.jpg"
import More from "../../../images/moreSermons.jpg"
import SEO from "../../../components/seo"
import { css } from "@emotion/core"

function SermonBox({
  imageUrl,
  imageAlt,
  sermonTitle,
  sermonLink,
  date,
  badge,
  pastor,
  sermonSeries,
  badgeColor,
}) {
  return (
    <Box
      as={Link}
      to={sermonLink}
      w="auto"
      h="auto"
      borderWidth="1px"
      rounded="lg"
      overflow="hidden"
      bg="#edf2f7"
      boxShadow="md"
      css={css`
        text-decoration: none;
        transition: 0.3s;
        &:hover {
          box-shadow: 4px 8px 16px 4px rgba(0, 0, 0, 0.2);
        }
      `}
    >
      <Image src={imageUrl} alt={imageAlt} w="100%" />

      <Box p="6">
        <Box alignItems="baseline">
          <Badge rounded="lg" px="8" py="2" variantColor={badgeColor} mt="0">
            {badge}
          </Badge>
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            mt="3"
          >
            {sermonSeries} - {pastor}
          </Box>
        </Box>
        <Box
          fontWeight="bold"
          as="h2"
          lineHeight="tight"
          isTruncated
          color="gray.900"
        >
          {sermonTitle}
        </Box>

        <Box color="gray.600" fontSize="sm">
          {date}
        </Box>
      </Box>
    </Box>
  )
}

function SermonBoxExternal({
  imageUrl,
  imageAlt,
  sermonTitle,
  sermonLink,
  date,
  badge,
  pastor,
  sermonSeries,
  badgeColor,
}) {
  return (
    <Box
      as="a"
      href={sermonLink}
      w="auto"
      h="auto"
      borderWidth="1px"
      rounded="lg"
      overflow="hidden"
      bg="#edf2f7"
      boxShadow="md"
      css={css`
        text-decoration: none;
        transition: 0.3s;
        &:hover {
          box-shadow: 4px 8px 16px 4px rgba(0, 0, 0, 0.2);
        }
      `}
    >
      <Image src={imageUrl} alt={imageAlt} w="100%" />

      <Box p="6">
        <Box alignItems="baseline">
          <Badge rounded="lg" px="8" py="2" variantColor={badgeColor} mt="0">
            {badge}
          </Badge>
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            mt="3"
          >
            {sermonSeries} &bull; {pastor}
          </Box>
        </Box>
        <Box
          fontWeight="bold"
          as="h2"
          lineHeight="tight"
          isTruncated
          color="gray.900"
        >
          {sermonTitle}
        </Box>

        <Box color="gray.600" fontSize="sm">
          {date}
        </Box>
      </Box>
    </Box>
  )
}

const IndexPage = () => (
  <Layout
    ImgSrc={BgImage}
    MainText="Radical Lectures"
    SecondText="Check out sermons from our Radical lectures series."
  >
    <SEO title="Sermons" />
    <Grid
      templateColumns={[
        "repeat(1, 1fr)",
        "repeat(1, 1fr)",
        "repeat(2, 1fr)",
        "repeat(3, 1fr)",
      ]}
      gap={10}
    >
      <SermonBox
        imageUrl={Radical}
        imageAlt="radical lectures image"
        sermonTitle="Moral Relativism"
        sermonSeries="Radical Lectures"
        sermonLink="/phoenix/sermons/moral-relativism"
        date="February 9, 2020"
        pastor="Scott Trefny"
      />
      <SermonBox
        imageUrl={Radical}
        imageAlt="radical lectures image"
        sermonTitle="Refugees and Immigrants"
        sermonSeries="Radical Lectures"
        sermonLink="/phoenix/sermons/refugees-and-immigrants"
        date="February 2, 2020"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={Radical}
        imageAlt="radical lectures image"
        sermonTitle="Gender Identity"
        sermonSeries="Radical Lectures"
        sermonLink="/phoenix/sermons/gender-identity"
        date="January 26, 2020"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={Radical}
        imageAlt="radical lectures image"
        sermonTitle="Sanctity of Human Life"
        sermonSeries="Radical Lectures"
        sermonLink="/phoenix/sermons/sanctity-of-human-life"
        date="January 19, 2020"
        pastor="Pastor Andrew"
        badge="popular"
        badgeColor="red"
      />
      <SermonBox
        imageUrl={More}
        imageAlt="view more sermons"
        sermonTitle="View More Sermons"
        sermonLink="/phoenix/sermons"
        sermonSeries="All Sermons"
        pastor="Cross Church Phoenix"
      />
    </Grid>
  </Layout>
)

export default IndexPage
